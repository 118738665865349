<template>
     <div class="bottom">
    <div @click.stop="enDownload"><img src="../assets/img/icon_download@2x.png" alt=""><span>MetaCoin Whitepaper (English)</span></div>
    <div @click.stop="zhDownload"><img src="../assets/img/icon_download@2x.png" alt=""><span>MetaCoin 白皮书 (中文)</span></div>
    </div>
</template>

<script>
export default {
    name:"Download",
    data(){
        return{

        }
    },
    methods:{    
        enDownload(){
            window.open('https://showpay.oss-cn-beijing.aliyuncs.com/showpay/metaCoinWhitePaper/MetaCoin%20White%20Paper%281%29.pdf')
        },
        zhDownload(){
            window.open('https://showpay.oss-cn-beijing.aliyuncs.com/showpay/metaCoinWhitePaper/MetaCoin%E7%99%BD%E7%9A%AE%E4%B9%A6%281%29.pdf')
        }
    }
}
</script>

<style lang='scss' scoped>
    .bottom{
        display: flex;
        flex-direction: row;
        margin-top:9.285714rem /* 130/14 */;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #3182F9;
            border-radius: .571429rem /* 8/14 */;
            padding:.928571rem /* 13/14 */ 1.785714rem /* 25/14 */;
            margin-right: 2.142857rem /* 30/14 */;
            cursor: pointer;
            &:hover{
                box-shadow: 2px 4px 6px  rgba($color: #3182F9, $alpha: 0.4);
                background-color: rgba($color: #3182F9, $alpha: 0.9);
            }
            img{
                width: 1.142857rem /* 16/14 */;
                height: 1.142857rem /* 16/14 */;
            }
            span{
                margin-left: .357143rem /* 5/14 */;
                color: #fff;
                font-size: 1.142857rem /* 16/14 */;
            }
        }
    }
@media screen and (max-width:500px){
    .bottom{
        display: flex;
        flex-direction: column;
        margin-top:3.571429rem /* 50/14 */ !important;
       div{
           margin-top: 1.428571rem /* 20/14 */;
       }
    }
}
</style>
<template>
  <div class="mcWrap">
      <div class="content">
          <div class="top">
              <img src="../assets/img/logo@2x.png" alt="">
              <span>MetaCoin</span>
          </div>
          <div class="center">
              MetaCoin is a token released by OpenMetaNetFoundation to motivate users to use MetalD applications and create MetalD transactions.
          </div>
          <Download></Download>
          <!-- <div class="bottom">
              <div><img src="../assets/img/icon_download@2x.png" alt=""><span>MetaCoin Whitepaper (English)</span></div>
              <div><img src="../assets/img/icon_download@2x.png" alt=""><span>MetaCoin 白皮书 (中文)</span></div>
          </div> -->
      </div>
      <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer.vue'
import Download from '../components/download.vue'
export default {
    name:"MetaCoin",
    components:{
        Footer,
        Download
    },
  data() {
    return {
      
    }
  },
 
}
</script>

<style lang='scss' scoped>
  .mcWrap{
    width: 80vw;
    padding-left: 8rem /* 112/14 */;
    padding-right: 8rem /* 112/14 */;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content{
    display: flex;
    flex-direction: column;  
    padding-top: 11.571429rem /* 162/14 */;
    .top{
         display: flex;
         flex-direction: row;
         align-items: center;
        img{
            width: 4.285714rem /* 60/14 */;
            height: 4.285714rem /* 60/14 */;
            object-fit: contain;
        }
        span{
            font-size: 2.571429rem /* 36/14 */;
            color: #101315;
            margin-left: 1.428571rem /* 20/14 */;
            font-family: SF Pro Medium !important;
        }
    }
    .center{
        width: 95%;
        font-size: 1.714286rem /* 24/14 */;
        color: #3E4244;
        line-height: 2.571429rem /* 36/14 */;
        margin-top: 4.285714rem /* 60/14 */;
    }
    // .bottom{
    //     display: flex;
    //     flex-direction: row;
    //     margin-top:9.285714rem /* 130/14 */;
    //     div{
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         background-color: #3182F9;
    //         border-radius: .571429rem /* 8/14 */;
    //         padding:.928571rem /* 13/14 */ 1.785714rem /* 25/14 */;
    //         margin-right: 2.142857rem /* 30/14 */;
    //         cursor: pointer;
    //         &:hover{
    //             box-shadow: 2px 4px 6px  rgba($color: #3182F9, $alpha: 0.4);
    //             background-color: rgba($color: #3182F9, $alpha: 0.9);
    //         }
    //         img{
    //             width: 1.142857rem /* 16/14 */;
    //             height: 1.142857rem /* 16/14 */;
    //         }
    //         span{
    //             margin-left: .357143rem /* 5/14 */;
    //             color: #fff;
    //             font-size: 1.142857rem /* 16/14 */;
    //         }
    //     }
    // }
    }
}
@media screen and (max-width:768px){
   .mcWrap{
    width: 80vw;
    padding-left: 2.142857rem /* 30/14 */;
    padding-right: 8rem /* 112/14 */;
}
}
@media screen and (max-width:500px){
    .mcWrap{
           width: 100vw;
        padding-left:.714286rem /* 10/14 */;
        padding-right: .714286rem /* 10/14 */;
    .content{
        padding-top: 11.428571rem /* 160/14 */;
    }
    }
}
</style>
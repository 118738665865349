<template>
   <div class="footer">
        <div class="footerLeft">
          <div @click.stop="toMetaID">MetaID</div>
          <div @click.stop="toMetaProtocols">MetaProtocols</div>
          <div><a target="_blank" href="mailto:showpay@showpay.io">Contact us: metaid@metaid.io</a></div>
        </div>
        <div class="footerRight">
          <div >© 2021 Open MetaNet Foundation, All Rights Reserved.</div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Footer"
}
</script>

<style lang='scss' scoped>
      .footer{
    // height: 5%;
    width: 85vw;
    height: auto;
    // height: 5rem /* 70/14 */;
    line-height: 1.5em;
    display: flex;
    flex-direction: row;
    margin-bottom: .714286rem /* 10/14 */;
    justify-content: space-between;
    align-items: center;
    // padding-left: 8rem /* 112/14 */;
    font-size: 1rem /* 14/14 */;
    font-family: PingFangSC-Regular, sans-serif;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      width: 30vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      justify-content: space-between;
      div{
        
        cursor: pointer;
        &:hover{
          color:#3182F9;
           opacity: 1;
          filter:alpha(opacity=100);
          -moz-opacity:1;
          -khtml-opacity:1;
        }
        
      }
      div:nth-of-type(3){
        >a{
          &:hover{
              color:#3182F9;
               opacity: 1;
          filter:alpha(opacity=100);
          -moz-opacity:1;
          -khtml-opacity:1;
          }
        }
      }
    }
    .footerRight{
    
      cursor: pointer;
    }

  }
  @media screen and (max-width:768px){
       .footer{
        margin-top: 3.571429rem /* 50/14 */;
        margin-left: .714286rem /* 10/14 */;
    }
}
@media screen and (max-width:500px){
  .footer{
        // margin-top:10.714286rem /* 150/14 */;
    line-height:1.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100vw;
    font-size: .928571rem /* 13/14 */;
    font-family: PingFangSC-Regular, sans-serif;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      display: flex;
      flex-direction: row;
    //   flex-wrap: wrap;
    //   justify-content: flex-start;
    }
    .footerRight{
      // padding: 0px;
      cursor: pointer;
      div{
          font-size: .928571rem /* 13/14 */;
      }
    }
    }
}
</style>